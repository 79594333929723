@import '~styles/global';

$img-path: '../../../../assets/images';

.SBLContentContainer {
  padding: 35px 10px;
  margin: 30px -6px 60px -6px;
  background-color: #fff;
  border: 1px solid $sbl-gray-5;
  border-right-width: 0;
  border-left-width: 0;

  @include medium {
    margin: 10px 0 48px 0;
    padding: 35px 20px 40px 20px;
    border-radius: 5px;
    border-right-width: 1px;
    border-left-width: 1px;
  }

  .ContainerTitle {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 20px;
  }
}

.SBLMobileDateText {
  position: absolute;
  padding: 10px;
  background-color: $sbl-gray-11;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  font-size: 14px;
  top: 1px;
  left: 1px;
  background-image: url('#{$img-path}/icons/calendar.png');
  background-size: 20px;
  background-position: calc(100% - 2px) center;
  background-repeat: no-repeat;
  width: 98px;
  box-sizing: border-box;
  height: 41px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @include medium {
    width: 72px;
  }
}

.SBLLabel {
  font-size: 8px;
  margin-bottom: 3px;
  text-transform: uppercase;
  font-weight: $font-weight-heavy;
}

.SBLSelector,
input.SBLDateTime {
  background-image: url('#{$img-path}/icons/double-arrow.png');
  background-size: 9px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
}

.SBLSelector.SelectorV2 {
  background-image: url('#{$img-path}/icons/chevron-down.svg');
  background-size: 11px;
  background-position: calc(100% - 15px) calc(50% + 1px);
}

input[type='number'].SBLInput {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

.SBLSelector {
  margin: 0;
}

.SBLSelector,
input.SBLDateTime,
input.SBLInput,
.SBLDownloadButton button {
  background-color: white;
  border: 2px solid $sbl-gray-5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  cursor: pointer;
  font-weight: 500;
  padding: 10px 0 10px 10px;
  text-transform: none;
  min-width: 100px;
  border-radius: 5px;
  white-space: nowrap;
  min-height: 39px;
  font-size: 16px;
  color: black;

  &:hover {
    background-color: $sbl-gray-10;
  }

  @include medium {
    padding: 5px 45px 5px 10px;
    min-height: 35px;
    min-width: 145px;
    font-size: 14px;
  }
}

.SBLSelector {
  background-color: white;
  box-sizing: border-box;
  border: 2px solid $sbl-gray-5;

  &:hover {
    background-color: $sbl-gray-10;
  }

  &.SelectorV2 {
    background-color: $sbl-gray-blue-10;
    border: none;
    border-radius: 17px;
    font-size: 13px;
    font-weight: 600;
    min-height: 35px;
    max-height: 35px;
    max-width: initial;
    width: initial;
    min-width: 114px;

    &:hover {
      background-color: $sbl-gray-4;
    }
  }
}

input.SBLInput {
  cursor: text;
  box-sizing: border-box;
  border: 1px solid $sbl-gray-5;

  &:hover {
    border: 2px solid $sbl-blue-8;
    background-color: $sbl-gray-11;
  }

  @include medium {
    &:hover {
      padding: 5px 45px 5px 9px;
    }
  }
}

input:disabled.SBLInput {
  background-color: white;
  border: 1px solid $sbl-gray-12;
  cursor: initial;
  color: black;

  &:hover {
    background-color: white;

    @include medium {
      padding: 5px 45px 5px 10px;
    }
  }
}

.SBLDateTime input {
  &::placeholder {
    color: black;
  }
}

.SBLDateTime.SBLDate input {
  background-image: url('#{$img-path}/icons/calendar.png');
  background-size: 20px;
}

.SBLButton {
  background-color: $sbl-blue-8;
  min-width: 105px;
  height: 39px;
  box-sizing: border-box;
  padding: 10px 5px;
  line-height: 19px;
  width: fit-content;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-weight: $font-weight-heavy;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: $sbl-blue-2;
  }

  &.negative {
    background-color: $sbl-gray-11;
    color: $sbl-red-2;

    &:hover {
      background-color: $sbl-gray-12;
    }
  }

  &.whiteButton {
    background-color: white;
    color: $sbl-blue-8;
    border: 1px solid $sbl-blue-8;

    &:hover {
      background-color: $sbl-gray-11;
      color: $sbl-blue-2;
      border-color: $sbl-blue-2;
    }
  }

  &.disabledButton {
    background-color: $sbl-blue-8;
    opacity: 0.5;
    cursor: default;
  }

  @include medium {
    height: 35px;
    font-size: 12px;
    padding: 10px;
    min-width: 125px;
    line-height: 15px;
  }
}

.SBLTable {
  margin-left: -10px;
  margin-right: -10px;

  @include medium {
    margin-left: -20px;
    margin-right: -20px;
  }

  tr {
    height: 65px;

    th:last-child,
    td:last-child {
      width: 20px;
      min-width: 20px;
    }

    th:first-child,
    td:first-child {
      width: 10px;

      @include medium {
        min-width: 20px;
      }
    }

    td {
      vertical-align: middle;
      border-bottom: 1px dotted $sbl-gray-5;
      font-size: 14px;
    }

    td:first-child,
    td:last-child {
      @include medium {
        border-bottom: none;
      }
    }

    @include medium {
      height: 48px;
    }
  }

  &.SBLTableBottomBorderless tr:last-child {
    td {
      border-bottom: none;
    }
  }

  thead {
    tr {
      background: $sbl-gray-10;
      border-bottom: 1px solid $sbl-gray-5;
      border-top: 1px solid $sbl-gray-5;
      height: 50px;

      th {
        color: $sbl-gray-6;
        font-weight: 900;
        font-size: 12px;
        vertical-align: center;
        text-transform: uppercase;
      }

      @include medium {
        height: 35px;

        th {
          font-size: 11px;
        }
      }
    }
  }
}

.SBLAlertIcon {
  background-color: $sbl-red-2;
  color: white;
  width: fit-content;
  padding: 5px 13px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 900;
}

.SBLCircleIcon {
  background-color: $sbl-gray-12;
  color: #000;
  padding: 4px 7px;
  border-radius: 100%;
  width: fit-content;
  font-size: 14px;
}

.SBLModal {
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 6px;
  padding: 30px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  background: #fff;

  h3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', sans-serif;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 900;
    border: none;
    font-size: 18px;
    text-align: left;
    width: 225px;
    margin-bottom: 30px;
    padding-bottom: 0 !important;

    @include medium {
      font-weight: 700;
    }
  }

  .modalHeaderButtons,
  i.close {
    background-image: url('#{$img-path}/icons/close-nav-black.png');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: $sbl-gray-11;
    background-size: 20px;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    height: 40px;
    width: 40px;
    border-radius: 5px;

    @include medium {
      background-color: transparent;
      right: 25px;
      top: 20px;
      height: 20px;
      width: 20px;
      padding: 8px;
      background-size: 17px;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }

  @include medium {
    height: auto;
    min-height: 400px;
    top: 8%;
    bottom: 8%;
    width: 500px;
    padding: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-left: 0;
    border: 1px solid $sbl-gray-5;
    border-radius: 5px;
  }

  .modalBottomButton {
    width: 100%;
    left: 0;
    bottom: 10px;
    box-sizing: border-box;
    line-height: 25px;
    align-self: flex-end;
    position: inherit;
    padding: 0;

    .SBLButton {
      width: 100%;
      height: 55px;
      line-height: 35px;
      margin-top: 25px;

      @include medium {
        height: 45px;
        line-height: 25px;
      }
    }

    .ButtonWithCopyLink {
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-gap: 10px;

      .SBLSocialButton {
        margin-top: -3px;
      }

      .SBLSocialButtonOuter {
        height: 55px;

        @include medium {
          height: 45px;
        }
      }
    }
  }
}

.SBLModalContent {
  display: block;
  flex-basis: 100%;
}

.SBLModal :global {
  .modal-title {
    padding: 0;
    margin: 0;
  }

  .modal-content {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    min-height: calc(100% - 80px);

    @include medium {
      min-height: calc(100% - 50px);
    }
  }
}

.SBLSocialButton :global {
  .twitter-white {
    background-color: #1da1f2;

    &:hover {
      background-color: #258fce;
    }

    div {
      background-image: url('#{$img-path}/icons/twitter-white.png');
    }
  }

  .facebook-white {
    background-color: #3b5998;

    &:hover {
      background-color: #2c4371;
    }

    div {
      background-image: url('#{$img-path}/icons/facebook-white.png');
    }
  }

  .instagram-white {
    background-color: #c13584;

    &:hover {
      background-color: #972968;
    }

    div {
      background-image: url('#{$img-path}/icons/instagram-white.png');
    }
  }

  .twitter div {
    background-image: url('#{$img-path}/icons/twitter-blue.png');
  }

  .facebook div {
    background-image: url('#{$img-path}/icons/facebook.png');
  }

  .email div {
    background-image: url('#{$img-path}/icons/email.png');
  }

  .sms div {
    background-image: url('#{$img-path}/icons/message.png');
  }

  .copylink div {
    background-image: url('#{$img-path}/icons/link.png');
  }

  .printer-grey {
    background-image: url('#{$img-path}/icons/printer-grey.png');
  }
}

.SBLSocialButtonOuter {
  background-color: $sbl-gray-11;
  position: relative;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  height: 39px;
  width: 65px;
  box-sizing: border-box;
  margin-bottom: -2px;

  @include medium {
    width: 75px;
    height: 35px;
  }

  &:hover {
    background-color: $sbl-gray-12;

    div {
      height: 25px;
      width: 25px;
    }
  }

  div {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    transition:
      width 0.3s,
      height 0.3s;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.SBLSocialButton :global {
  .menu-download-container {
    top: 0 !important;
    left: 0 !important;
  }
}

.SBLInfoBlock {
  width: 100%;
  border-bottom: 1px dotted $sbl-gray-5;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.SBLInfoBlockContent {
  display: flex;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
}

.SBLInfoBlockIcon {
  margin-right: 10px;

  img,
  div {
    height: 25px;
    width: 25px;
  }

  div {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.SBLSocialButtonWhite {
  background-color: #fff !important;
}

.SBLInfoBlockIcon :global {
  div.home {
    background-image: url('#{$img-path}/icons/home.png');
  }

  div.location {
    background-image: url('#{$img-path}/icons/location.png');
  }

  div.lightning {
    background-image: url('#{$img-path}/icons/lightning.png');
  }

  div.team {
    background-image: url('#{$img-path}/icons/team.png');
  }

  div.time {
    background-image: url('#{$img-path}/icons/time.png');
  }

  div.calendar {
    background-image: url('#{$img-path}/icons/calendar.png');
  }
}

.SBLDownloadButton button {
  padding: inherit;
  min-width: 75px !important;
}

.SBLDownloadButton :global {
  .tooltiptext {
    display: none !important;
  }

  .clear-dropdown {
    z-index: -20;
  }

  .tooltip {
    .print-white {
      background-image: url('#{$img-path}/icons/printer-grey.png');
      background-size: 20px;
      transition: background-size 0.3s linear;
      height: 25px;
      width: 25px;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
    }

    &:hover {
      .print-white {
        background-size: 25px;
      }
    }
  }
}

.SBLTeamBlock {
  display: inline-flex;

  div {
    font-weight: $font-weight-bold;
    line-height: 30px;
    min-width: 25px;
    display: inline-block;
    font-size: 16px;

    @include medium {
      font-size: 14px;
    }
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  div.SBLTeamBlockVS {
    font-weight: $font-weight-regular;
    color: black;
  }

  div.SBLTeamBlockName {
    @extend %truncate;

    width: 120px;

    @include medium {
      width: auto;
    }
  }
}

.SBLProfileImageGroup {
  position: relative;
  margin-bottom: 50px;
}

.SBLProfileImageGroup :global {
  .image-input img {
    opacity: 0.7;
  }

  .player-image {
    bottom: -50px;
    margin-left: 20px;
    border-radius: 50%;
    overflow: hidden;

    @include medium {
      margin-left: 10px;
    }
  }

  .player-cover_image {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .upload-btn {
    background-image: url('#{$img-path}/icons/camera.png');
    background-color: transparent;
    border: none;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    height: 30px;
    margin: auto;
    width: 30px;
    transition:
      height 0.3s,
      width 0.3s;
  }

  .image-input:hover .upload-btn {
    height: 35px;
    width: 35px;
  }

  .image-input.player-cover_image {
    padding-bottom: 140px;
  }

  @include medium {
    .image-input.player-cover_image {
      padding-bottom: 150px;
    }
  }
}

.SBLInputBlock {
  width: 100%;

  .SBLInputBlockHeader {
    font-weight: $font-weight-heavy;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .SBLInputBlockUpdate {
    font-size: 14px;
    font-weight: $font-weight-heavy;
    text-transform: uppercase;
    color: $sbl-blue-8;
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;

    &:hover {
      color: $sbl-blue-2;
    }
  }

  .SBLInputBlockSubLabel {
    font-size: 12px;
    font-weight: $font-weight-regular;
    color: $sbl-gray-6;
    margin-left: 5px;
  }
}

.SBLLargeInput {
  input,
  input:disabled {
    border: 1px solid $sbl-gray-5;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 3px;
    text-transform: none;
    height: 50px;
    font-size: 14px;
    max-width: 350px;
    color: #000000;
    opacity: 1;
    -webkit-text-fill-color: #000000;
  }

  input.SBLInput:hover {
    background-color: #ffffff;
  }
}

.SBLFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SBLTeamImageGroup {
  display: flex;

  i,
  img {
    height: 26px !important;
    width: 26px !important;
    margin-right: 15px;
  }

  &:hover {
    .IGTopText,
    .IGBottomText {
      color: $sbl-blue-2;
    }
  }

  .IGTopText,
  .IGBottomText {
    color: $sbl-blue-8;
  }

  .IGTopText {
    font-size: 16px;
    font-weight: 900;

    @include medium {
      font-size: 14px;
    }
  }

  .IGBottomText {
    display: block;
    font-size: 14px;
    font-weight: $font-weight-regular;

    @include medium {
      font-size: 12px;
    }
  }
}

.MarginRight {
  margin-right: 10px;
}

.SBLErrorMessage {
  color: $sbl-red-2;
  margin-bottom: 25px;
}

.hideMobile {
  @include small {
    display: none !important;
  }
}

.hideDesktop {
  @include medium {
    display: none !important;
  }
}
