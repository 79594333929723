@use 'sass:math';
@use 'sass:list';
@use 'sass:meta';
@use 'sass:map';

// Colors
$sbl-gray-1: #f5f6f9;
$sbl-gray-2: #f2f8fd;
$sbl-gray-3: #f7fafd;
$sbl-gray-4: #eaecf0;
$sbl-gray-5: #e7e8ec;
$sbl-gray-6: #888a8c;
$sbl-gray-7: #d0d0d0;
$sbl-gray-8: #48494a;
$sbl-gray-9: #cbd6e2;
$sbl-gray-10: #f6f7f8;
$sbl-gray-11: #f3f4f6;
$sbl-gray-12: #dddddd;
$sbl-blue-gray-1: #d0d6e3;
$sbl-blue-gray-2: #5b667c;

$sbl-blue-1: #3e89f7;
$sbl-blue-2: #0e75eb;
$sbl-blue-3: #405fee;
$sbl-blue-4: #3e89f7;
$sbl-blue-5: #2776ea;
$sbl-blue-6: #4672d6;
$sbl-blue-7: #4b6bc4;
$sbl-blue-8: #439ded;

$sbl-dark-blue-1: #2a2d43;
$sbl-dark-blue-2: #2d3148;
$sbl-dark-blue-3: #484b5f;
$sbl-dark-blue-4: #7f839a;
$sbl-dark-blue-5: #535669;
$sbl-dark-blue-6: #142b59;
$sbl-dark-blue-7: #2d4880;
$sbl-dark-blue-8: #4464a8;
$sbl-dark-blue-9: #3b3d4e;

$sbl-light-blue-1: #09bbf2;
$sbl-light-blue-2: #07a3d3;
$sbl-light-blue-3: #bacdff;

$sbl-gray-blue-1: #e5e8f1;
$sbl-gray-blue-2: #f0f2f5;
$sbl-gray-blue-3: #828a9b;
$sbl-gray-blue-4: #5b667d;
$sbl-gray-blue-5: #e7eef8;
$sbl-gray-blue-6: #a8b0c2;
$sbl-gray-blue-7: #f2f8fd;
$sbl-gray-blue-8: #f7fafd;
$sbl-gray-blue-9: #e5e8f1;
$sbl-gray-blue-10: #f5f6f9;

$sbl-green-1: #5cc489;
$sbl-green-2: #46b760;

$sbl-red-1: #e1464a;
$sbl-red-2: #d05250;

$sbl-cream: #f5f0e0;

$box-shadow-1: 0 3px 5px 0 #94949442;
$box-shadow-2: 0 1px 3px 0 #94949442;

$font-size: 16px;

$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-default: 400;
$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

%truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Page Sizes
$small: 768px;
$medium: 1024px;
$large: 1280px;

@mixin small {
  @media (max-width: $small) {
    @content;
  }
}

@mixin medium {
  @media (min-width: ($small + 1px)) {
    @content;
  }
}

@mixin not-large {
  @media (max-width: ($medium - 1px)) {
    @content;
  }
}

@mixin large {
  @media (min-width: $medium) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: $large) {
    @content;
  }
}

@mixin xxlarge {
  @media (min-width: 1366px) {
    @content;
  }
}

@mixin superxlarge {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin container-min($width) {
  @container (min-width: #{$width}) {
    @content;
  }
}

@mixin container-max($width) {
  @container (max-width: #{$width}) {
    @content;
  }
}

@function px-to-rem($px-value, $base-font-size: 16px) {
  @return math.div($px-value, $base-font-size) * 1rem;
}

@mixin font-size($size) {
  font-size: px-to-rem($size);
}

@mixin letter-spacing($size, $xd-spacing: 10) {
  letter-spacing: px-to-rem(math.div(math.div($size, $xd-spacing), 10));
}

@mixin line-height($size) {
  line-height: px-to-rem($size);
}

// https://github.com/yell0wsuit/Apple-Fonts-Documentation
$font-weights: (
  'ULTRALIGHT': 100,
  'THIN': 200,
  'LIGHT': 300,
  'REGULAR': 400,
  'MEDIUM': 500,
  'SEMIBOLD': 600,
  'BOLD': 700,
  'HEAVY': 800,
  'BLACK': 900
);

@mixin font-weight($weight) {
  font-weight: map-get($font-weights, $weight);
}

@mixin font($size: null, $weight: null) {
  @if $size {
    @include font-size($size);
    // @include letter-spacing($size);
  }
  @if map-has-key($font-weights, $weight) {
    @include font-weight($weight);
  }
}

@mixin column-weights($args...) {
  $values: ();
  $total: 0;
  $lastVisibleIndex: 0;

  // Check if the first argument is a keyword argument
  @if meta.keywords($args) != () {
    // If keyword arguments, extract values ignoring keys
    $values: map.values(meta.keywords($args));
  } @else {
    // If regular arguments, use them directly
    $values: $args;
  }

  // Calculate the total sum of values
  @each $value in $values {
    $total: $total + $value;
  }

  // Determine the index of the last visible child
  @for $i from 1 through length($values) {
    @if nth($values, $i) != 0 {
      $lastVisibleIndex: $i;
    }
  }

  // Apply calculated widths to child elements
  @for $i from 1 through length($values) {
    $value: nth($values, $i);

    th:nth-child(#{$i}),
    td:nth-child(#{$i}) {
      @if $value == 0 {
        display: none;
      } @else {
        display: table-cell;
        width: percentage(math.div($value, $total));
        @if $i == $lastVisibleIndex {
          padding-right: 14px;
        }
      }
    }
  }
}

@mixin max-lines($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
